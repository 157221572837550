import {
    createContext,
    useEffect,
    useState
} from "react";
import { getTenantAuth } from "../../globalContext";
import { identify } from "../../services/analytics-service";

interface IAuthContext {
    phoneNumber: string,
    setTenantAuth: (tenantAuth: { phoneNumber: string }) => void
};

export const AuthContext = createContext<IAuthContext>({
    phoneNumber: "",
    setTenantAuth: (_: { phoneNumber: string }) => { }
});

const AuthProvider = ({ children }: { children: any }) => {
    // tenant auth is hydrated from the server. It's either there or it isn't.
    const [tenantAuth, setTenantAuth] = useState(getTenantAuth());

    useEffect(() => {
        if (tenantAuth?.phoneNumber) {
            identify(tenantAuth.phoneNumber)
        }
    }, [tenantAuth])

    return (
        <AuthContext.Provider
            value={{
                // we can set the tenant auth state if we successfully authorize
                // we don't need to update the global context as that global context with the tenant auth
                // is only used on load to set up this auth provider.
                // if the authorization was successful on the server, then the next time we load the app,
                // it will be there on the global context
                setTenantAuth,
                phoneNumber: tenantAuth?.phoneNumber || "",
            }}
        >
            {children}
        </AuthContext.Provider>

    )
}

export default AuthProvider;
