import { BottomNavigation, BottomNavigationAction, Paper, styled } from '@mui/material';
import { useLocation } from "react-router-dom";
import { isIOS } from "react-device-detect";
import { HomeIcon } from './components/icons/HomeIcon';
import { BackIcon } from './components/icons/BackIcon';
import { ChatIcon } from './components/icons/ChatIcon';
import { FacilityIcon } from './components/icons/FacilityIcon';
import { AccountIcon } from './components/icons/AccountIcon';
import { companyConfig, logoBase64String } from './globalContext';
import { useSelectFacility } from './hooks/useSelectFacility';
import { useStoreSelfNavigation } from './hooks/useStorSelfNavigation';
import useAnalytics from './hooks/useAnalytics';
import { startChat } from './startChat';
import { BannerType } from './types/BannerType';
import { useContext } from 'react';
import { PopoverContext } from './providers/PopoverContextProvider';

type LayoutProps = {
    children: React.ReactNode,
    noBackButton?: boolean,
}

const clipPaths = {
    [BannerType.Chevron]: {
        innerLarge: 'polygon(0% 0%, 100% 0%, 100% 65%, 50% 100%, 0% 65%)',
        innerSmall: 'polygon(0% 0%, 100% 0%, 100% 90%, 50% 100%, 0% 90%)',
        outerLarge: 'polygon(0% 0%, 100% 0%, 100% 67%, 50% 100%, 0% 67%)',
        outerSmall: 'polygon(0% 0%, 100% 0%, 100% 91%, 50% 100%, 0% 91%)',
    },
    [BannerType.InverseChevron]: {
        innerLarge: 'polygon(0% 0%, 100% 0%, 100% 95%, 50% 75%, 0% 95%)',
        innerSmall: 'polygon(0% 0%, 100% 0%, 100% 95%, 50% 90%, 0% 95%)',
        outerLarge: 'polygon(0% 0%, 100% 0%, 100% 95%, 50% 77%, 0% 95%)',
        outerSmall: 'polygon(0% 0%, 100% 0%, 100% 95%, 50% 91%, 0% 95%)',
    },
    [BannerType.Block]: {
        innerLarge: 'polygon(0% 0%, 100% 0%, 100% 88%, 0% 88%)',
        innerSmall: 'polygon(0% 0%, 100% 0%, 100% 88%, 0% 88%)',
        outerLarge: 'polygon(0% 0%, 100% 0%, 100% 89%, 0% 89%)',
        outerSmall: 'polygon(0% 0%, 100% 0%, 100% 89%, 0% 89%)',
    }
}

const config = {
    clipPath: clipPaths[companyConfig.bannerDesign] || clipPaths[BannerType.InverseChevron]
}

export const PageLayout = ({
    children
}: LayoutProps) => {
    const { pathname } = useLocation();
    const navigate = useStoreSelfNavigation();
    const { trackClickEvent } = useAnalytics("Layout")

    const isLandingPage = pathname === "/";

    const { showSelectFacility } = useSelectFacility(!isLandingPage)

    return (
        <PageLayoutContainer>
            <Banner />

            {children}

            <Paper sx={{ opacity: 0, height: isIOS ? "150px" : "100px", width: "100%" }} elevation={3} />
            <Paper sx={{
                transition: 'bottom 0.5s ease-out',
                position: 'fixed',
                bottom: 0,
                left: 0, right: 0,
            }} elevation={3}>
                <BottomNavigation
                    showLabels
                >
                    {!isLandingPage &&
                        <BottomNavigationAction
                            onClick={() => {
                                trackClickEvent("Navigate Back")
                                navigate(-1)
                            }}
                            label={"Back"}
                            icon={<BackIcon />} />
                    }

                    <BottomNavigationAction
                        onClick={() => {
                            trackClickEvent("Navigate FacilitySelect")
                            showSelectFacility()
                        }}
                        label={"Change Facility"}
                        icon={<FacilityIcon />} />

                    {!isLandingPage &&
                        <BottomNavigationAction
                            onClick={() => {
                                trackClickEvent("Navigate Home")
                                navigate("/")
                            }}
                            label="Home" icon={<HomeIcon />} />
                    }

                    <BottomNavigationAction
                        onClick={() => {
                            trackClickEvent("Navigate Account")
                            navigate("/account")
                        }}
                        label="Account" icon={<AccountIcon />} />

                    {
                        companyConfig.swivlId
                        && <BottomNavigationAction
                            onClick={() => {
                                startChat();
                                trackClickEvent("Open Chat");
                            }}
                            label="Chat"
                            icon={<ChatIcon />} />
                    }
                </BottomNavigation>
            </Paper>
        </PageLayoutContainer >
    );
}

// TODO: Style the image size better
const LogoImage = styled("img")({
    cursor: "pointer",
    maxWidth: "100%",
    maxHeight: "100%",
    display: "block"
})

const LogoContainer = styled("div")(({ popoverVisible }: { popoverVisible?: boolean }) => ({
    padding: (popoverVisible && companyConfig.bannerDesign === 0) ? "32px" : "24px",
    boxSizing: "border-box",
    height: popoverVisible
        ? "100%"
        : "75%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: 'height 0.7s ease-in'
}))

const BannerBox = styled("div")(({ popoverVisible }: { popoverVisible?: boolean }) => ({
    width: "100vw",
    height: popoverVisible ? "15vh" : "33vh",
    backgroundColor: companyConfig.bannerBackgroundColor,
    clipPath: popoverVisible
        ? config.clipPath.innerSmall
        : config.clipPath.outerLarge,
    transition: 'clip-path 0.9s ease-in-out, height 0.9s ease-out',
    zIndex: 50,
}))

const BannerBoxContainer = styled("div")(({ popoverVisible, ismobile }: { popoverVisible?: boolean, ismobile?: boolean }) => ({
    ...(ismobile
        ? {}
        : { display: "none" }),
    paddingBottom: popoverVisible ? "10px" : "14px",
    width: "100vw",
    height: popoverVisible ? "15vh" : "33vh",
    backgroundColor: companyConfig.bannerBorderColor,
    clipPath: popoverVisible
        ? config.clipPath.outerSmall
        : config.clipPath.outerLarge,
    transition: 'clip-path 0.9s ease-in-out, height 0.9s ease-out, padding 0.9s ease-out, margin 0.9s ease-out',
    marginBottom: popoverVisible ? "16px" : "24px",
    zIndex: 50,
    position: "relative",
}))

const PageLayoutContainer = styled("div")(() => ({
    backgroundColor: "#FCFCFC",
    position: "relative",
    height: "100vh",
    width: "100vw",
    overflowY: "scroll",
    boxSizing: "border-box",
}));


export const Banner = () => {
    const { popoverVisible } = useContext(PopoverContext)
    const { pathname } = useLocation();
    const navigate = useStoreSelfNavigation();

    return (
        <BannerBoxContainer popoverVisible={popoverVisible || pathname != "/"} ismobile={true}>
            <BannerBox popoverVisible={popoverVisible || pathname != "/"} >
                <LogoContainer popoverVisible={popoverVisible || pathname != "/"} >
                    <LogoImage
                        onClick={() => navigate("/")} src={`data:image/jpeg;base64,${logoBase64String}`}
                    />
                </LogoContainer>
            </BannerBox>
        </BannerBoxContainer>
    )
}
