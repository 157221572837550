import axios, { AxiosResponse } from 'axios';
import { companyConfig } from '../globalContext';
import { ILedger } from '../types/ILedger';
import { IPaymentMethod } from '../types/PaymentMethod';
import demoAgent from './demo/demoAgent';

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"

axios.defaults.withCredentials = true;

const responseBody = <T>(response: AxiosResponse<T>) => {
    return response.data
};

export const storageKeys = {
    phoneToken: "phoneToken"
}

let accessToken = localStorage.getItem(storageKeys.phoneToken) || ""
export const getToken = () => accessToken || localStorage.getItem(storageKeys.phoneToken) || ""
export const setLocalStorageToken = (token: string) => {
    accessToken = token
}

const requests = {
    get: <T>(url: string) => {
        return axios.get<T>(url, {
            headers: {
                "PhoneToken": getToken(),
            }
        }).then(responseBody)
    },
    post: <T>(url: string, body: {}) => axios.post<T>(url, body, {
        headers: {
            "PhoneToken": getToken(),
        }
    }).then(responseBody).catch((error) => {
        // TODO: format the different errors and log to sentry accordingly and display appropriate response to user
        return {
            error: error.response?.data?.toString()
        }
    }),
    postImage: <T>(url: string, body: {}) => axios.post<T>(url, body, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body, {
        headers: {
            "PhoneToken": getToken(),
        }
    }).then(responseBody),
    delete: <T>(url: string) => axios.delete<T>(url, {
        headers: {
            "PhoneToken": getToken(),
        }
    }).then(responseBody),
}

const Facility = {
    getFacilities: () => requests.get<any>("/api/Facilities"),
    getLedgerByPhoneNumber: (phoneNumber: string, facilityID: string):
        Promise<any> => requests.get<any>(`/GetTenantLedgers/${facilityID}?phoneNumber=${phoneNumber}&companyID=${companyConfig.companyApiId}`),
    initiateMoveOut: (imageData: FormData) => requests.postImage<any>("/api/facilities/initiate-move-out", imageData),
    initiateRemoteMoveOut: (imageData: any) => requests.post<any>("/api/facilities/initiate-remote-move-out", imageData),
    initiateAuctionMoveOut: (imageData: FormData) => requests.postImage<any>("/api/facilities/initiate-auction-move-out", imageData),
}

const Account = {
    getAccount: () => requests.get<{ data?: ILedger[] }>(`/api/accounts/auth-user/ledgers`),
    getLedgerByPhoneNumber: (phoneNumber: string, facilityID: string):
        Promise<any> => requests.get<any>(`/api/accounts/ledgers/${facilityID}?phoneNumber=${phoneNumber}`),
}

const Access = {
    getAccessPoints: (facilityID: string) => requests.get<any>(`/api/access/access-points/${facilityID}`),
    openAccessPoint: (facilityID: string, accessID: string) => requests.get<any>(`/api/access/access-points/${facilityID}/open/${accessID}`),
    getLocks: (facilityID: string) => requests.get<any>(`/api/access/locks/${facilityID}`),
    getTenantOwnedLocks: (facilityID: string) => requests.get<any>(`/api/access/tenant-locks/${facilityID}`),
}

const OTP = {
    sendOTP: (phoneNumber: string) => axios.post<string>("/api/auth/otp/send?phoneNumber=" + phoneNumber, {}, { withCredentials: true }).then(responseBody),
    resendOTP: () => axios.post<string>("/api/auth/otp/resend", {}, { withCredentials: true }).then(responseBody),
    verifyOTP: (otp: string) => axios.post<string>(`/api/auth/otp/verify?otp=${otp}`, {}, { withCredentials: true }).then(responseBody),
}

const Payments = {
    getLedgerEligibility: (facilityId: string, ledgerId: string):
        Promise<{
            data: {
                eligibleForPayment: boolean
            }
        }> => requests.get<any>(`/api/payments/${facilityId}/ledger-eligibility/${ledgerId}`),
    getLedgerPrepayAmount: (facilityId: string, ledgerId: string, prepayMonths: number):
        Promise<{
            data: {
                amount: number
            }
        }> => requests.get<any>(`/api/payments/${facilityId}/ledger-prepay-amount/${ledgerId}?prepayMonths=${prepayMonths}`),
    getPaymentMethods: (facilityId: string, tenantId: string, ledgerId: string):
        Promise<{ data: IPaymentMethod[] }> => requests.get<any>(`/api/payments/payment-methods/${facilityId}?tenantId=${tenantId}&ledgerId=${ledgerId}`),
    getAllowedPrePayMonths: ():
        Promise<{ data: number }> => requests.get<any>(`/api/settings/prepay-months`),
}

const agents = {
    Account,
    Access,
    Facility,
    OTP,
    Payments,
    ...(_context.companyConfig.subdomain === "demo" ? demoAgent : {})
}

export default agents
