import React, { useEffect, useState } from "react";
import { styled } from "@mui/material";
import {
    Routes,
    Route,
    useLocation,
    Navigate,
} from "react-router-dom";

import {
    AccountPage,
    ContactUsPage,
    ExistingCustomerContactUsPage,
    ExistingCustomerPage,
    LandingPage,
    NewCustomerPage,
} from "../pages";
import { MoveOutContextProvider } from "../providers/moveout-provider/MoveOutProvider";
import { PageLayout } from "../PageLayout";
import { PaymentsContextProvider } from "../providers/payments-provider/PaymentsProvider";
import { SmsVerificationWrapper } from "../wrappers/sms-verification/SmsVerificationWrapper";
import AnalyticsProvider from "../providers/AnalyticsProvider";
import { mainRoutes } from "./routes.ts"
import { ErrorBoundary, ExceptionPage } from "../utils/errorHandling.tsx";
import AuctionBuyerRoutes from "../pages/auction-buyer/AuctionBuyerRoutes.tsx";
import NewMoveInAccountPage from "../pages/account/new-move-in/NewMoveInAccountPage.tsx";

export const PaymentRoutes = React.lazy(() => import('../pages/payments/PaymentsRoutes'));
const AccessRoutes = React.lazy(() => import('../pages/access/AccessRoutes'));
const MoveoutRoutes = React.lazy(() => import('../pages/moveout/MoveoutRoutes'));

export const useAnalyticsRoute = (Component: () => JSX.Element, pageName: string) => {
    return (
        <ErrorBoundary fallback={ExceptionPage}>
            <AnalyticsProvider pageName={pageName}>
                <Component />
            </AnalyticsProvider>
        </ErrorBoundary>
    )
}

const AppRouter = () => {
    const { pathname, search } = useLocation()
    const [tryAgain, setTryAgain] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0);
        try {
            const swizl = document.getElementsByClassName("sce-Dot")[0] as HTMLDivElement

            if (!swizl) {
                if (tryAgain) {
                    setTimeout(() => setTryAgain(false), 500)
                }
                return;
            }

            if (swizl && swizl.style) {
                swizl.style.display = "none"
                swizl.style.top = "0px"
            }
        } catch {

        }
    }, [pathname, search, tryAgain])

    return (
        <PageLayout noBackButton={pathname === "/"}>
            <Section>
                <Routes>
                    <Route index element={useAnalyticsRoute(LandingPage, "Landing")} />
                    <Route path={mainRoutes.NewCustomer} element={useAnalyticsRoute(NewCustomerPage, "NewCustomer")} />

                    <Route path={mainRoutes.ContactUs} element={useAnalyticsRoute(ContactUsPage, "NewCustomer/ContactUs")} />
                    <Route path={mainRoutes.ExistingCustomer + mainRoutes.ContactUs} element={useAnalyticsRoute(ExistingCustomerContactUsPage, "ExistingCustomer/ContactUs")} />

                    <Route path={mainRoutes.ExistingCustomer} element={useAnalyticsRoute(ExistingCustomerPage, "ExistingCustomer")} />


                    <Route path={mainRoutes.Access} element={
                        <SmsVerificationWrapper>
                            <React.Suspense fallback={<div>Loading...</div>}>
                                <AccessRoutes />
                            </React.Suspense>
                        </SmsVerificationWrapper>
                    } />

                    <Route path={mainRoutes.Payments} element={
                        <SmsVerificationWrapper>
                            <PaymentsContextProvider>
                                <React.Suspense fallback={<div>Loading...</div>}>
                                    <PaymentRoutes />
                                </React.Suspense>
                            </PaymentsContextProvider>
                        </SmsVerificationWrapper>
                    } />

                    <Route path={mainRoutes.Account} element={useAnalyticsRoute(() =>
                        <SmsVerificationWrapper>
                            <AccountPage />
                        </SmsVerificationWrapper>, "Account")}
                    />

                    <Route path={mainRoutes.Account + "/new-move-in"} element={useAnalyticsRoute(() =>
                        <SmsVerificationWrapper>
                            <NewMoveInAccountPage />
                        </SmsVerificationWrapper>, "Account")}
                    />

                    <Route path={mainRoutes.MoveOut} element={
                        <MoveOutContextProvider>
                            <React.Suspense fallback={<div>Loading...</div>}>
                                <MoveoutRoutes />
                            </React.Suspense>
                        </MoveOutContextProvider>
                    } />

                    <Route path={mainRoutes.AuctionBuyer + "/*"} element={
                        <SmsVerificationWrapper>
                            <React.Suspense fallback={<div>Loading...</div>}>
                                <AuctionBuyerRoutes />
                            </React.Suspense>
                        </SmsVerificationWrapper>
                    } />

                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Section>
        </PageLayout >
    )
}

export const Section = styled("div")({
    padding: "0 24px",
    maxWidth: "475px",
    margin: "auto",
})

export default AppRouter;
