import {
    useEffect, useMemo
} from "react";
import { FacilitiesContext } from "./FacilitiesContext";
import { IFacility } from "../../types/IFacility";
import { calcLatLongDistance, isAtFacility } from "../../utils/calcLatLongDistance";
// import { useClosestFacility } from "./[hooks]/useClosestFacility";
import { useLatLong } from "../../hooks/useLatLong";
import { useSelectFacility } from "../../hooks/useSelectFacility";
import { useFetchFacilities } from "./[hooks]/useFetchFacilities";
import { useFacilityIdParam } from "./[hooks]/useFacilityIdParam";
import { companyConfig } from "../../globalContext";

console.log("hi")

const FacilitiesProvider = ({ children }: { children: any }) => {
    const [facilityIDParam, setFacilityIDParam] = useFacilityIdParam()
    const [facilities, fetchingFacilities] = useFetchFacilities()

    const selectedFacility = facilities.find(f => f.id === facilityIDParam);
    const setSelectedFacility = (facility: IFacility, options?: { replace: boolean }) => {
        if (options?.replace) {
            setFacilityIDParam(facility.id, "replaceIn")
        } else {
            setFacilityIDParam(facility.id)
        }
    }

    const currentPosition = useLatLong();

    // sort facilities by closest to furthest
    const sortedFacilities = useMemo(() =>
        !currentPosition.error && !currentPosition.loading
            ? [...facilities].sort((a: IFacility, b: IFacility) => {
                const aD = (a.latitude && a.longitude) ? calcLatLongDistance(
                    currentPosition.latitude, currentPosition.longitude,
                    a.latitude, a.longitude
                ) : Infinity

                const bD = (b.latitude && b.longitude) ? calcLatLongDistance(
                    currentPosition.latitude, currentPosition.longitude,
                    b.latitude, b.longitude
                ) : Infinity

                return aD - bD
            })
            : facilities
        , [facilities, currentPosition])

    const closestFacility =
        companyConfig.subdomain === "demo" || (!currentPosition.error && !currentPosition.loading) ? sortedFacilities[0] : null

    const { showSelectFacility } = useSelectFacility(false, {
        facilities: sortedFacilities,
        selectedFacility,
        setSelectedFacility,
        closestFacility
    })

    const facility = selectedFacility;


    let atFacility = useMemo(() => isAtFacility(
        currentPosition.latitude,
        currentPosition.longitude,
        selectedFacility?.latitude || 0,
        selectedFacility?.longitude || 0,
        251
    ), [currentPosition.latitude, currentPosition.longitude, selectedFacility?.latitude, selectedFacility?.longitude])

    if (_context.companyConfig.subdomain == "demo") {
        atFacility = true
    }

    // console.log({ currentPosition, facility, atFacility })

    useEffect(() => {
        if (fetchingFacilities || selectedFacility) {
            return
        } else if (companyConfig.subdomain === "demo") {
            setSelectedFacility(facilities[0], { replace: true })
        } else if (
            !selectedFacility
            && closestFacility
            && isAtFacility(
                currentPosition.latitude,
                currentPosition.longitude,
                closestFacility?.latitude || 0,
                closestFacility?.longitude || 0,
                250
            )) {
            setSelectedFacility(closestFacility, { replace: true })
        } else if (currentPosition.error && !facilityIDParam && !selectedFacility) {
            showSelectFacility()
        } else if (!atFacility && !selectedFacility && closestFacility) {
            showSelectFacility()
        }
    }, [!selectedFacility, closestFacility, currentPosition, facilityIDParam])

    return (
        <FacilitiesContext.Provider
            value={{
                setSelectedFacility,
                selectedFacility: selectedFacility || null,
                facilities: sortedFacilities,
                locationError: currentPosition.error?.message || "",
                atFacility,
                fetchingFacilities,
                closestFacility,
                currentPosition,  //TODO: move to it's own context
            }}
        >
            {children}
        </FacilitiesContext.Provider>
    )
}

export default FacilitiesProvider;
