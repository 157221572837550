import React from 'react'
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material'

import './index.css'
import App from './App.tsx'
import { theme } from './brand/theme.ts';
import { captureException, initializeErrorHandling, isInitialized } from './utils/errorHandling.tsx';

try {
  initializeErrorHandling();
} catch (error) {
  if (isInitialized()) {
    captureException(error);
  } else {
    // alert(JSON.stringify(error?.message))
  }
}

console.log("reporting done")

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
)
